import React, { useContext, useState } from 'react'

const AdminContext = React.createContext(null)

export const useAdmin = () => {
  return useContext(AdminContext)
}

export const AdminContextConsumer = AdminContext.Consumer

export const VIEW_AS_USER = {
  id: null,
  name: null,
  email: null
}

function setViewAsUserOnGlobalScope(user) {
  if (user) {
    VIEW_AS_USER.id = user.id
    VIEW_AS_USER.name = user.name
    VIEW_AS_USER.email = user.email
  } else {
    VIEW_AS_USER.id = null
    VIEW_AS_USER.name = null
    VIEW_AS_USER.email = null
  }
}

const AdminProvider = ({ viewAsUserId, children }) => {
  const [currentAdmin, setCurrentAdmin] = useState(null)
  const [viewAsUser, setViewAsUser] = useState(null)
  const state = {
    currentAdmin,
    setCurrentAdmin,
    viewAsUser,
    setViewAsUser: (user) => {
      setViewAsUser(user)
      setViewAsUserOnGlobalScope(user)
    },
    setViewAsUserWithoutReRender: setViewAsUserOnGlobalScope
  }
  if (viewAsUserId) {
    VIEW_AS_USER.id = viewAsUserId
  }
  return <AdminContext.Provider value={state}>{children}</AdminContext.Provider>
}

export default AdminProvider
