import React from 'react'
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider,
  InMemoryCache,
  fromPromise,
  Observable,
  ApolloError
} from '@apollo/client'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { print } from 'graphql/language/printer'
import * as Sentry from '@sentry/gatsby'
import { relayStylePagination } from '@apollo/client/utilities'
import { SentryLink } from 'apollo-link-sentry'
import { Auth } from '@aws-amplify/auth'
import { Logger } from '@aws-amplify/core'
import { currentCredentials } from './utils/credentials'
import {
  signGraphQLRequest,
  getParams,
  getCookie,
  makeErrorJSONFriendly,
  COOKIE_LOCALE_TO_SCHEMA_LOCALE,
  ANONYMOUS_USER_ID
} from './utils/utils'
import LocalizationProvider from './src/components/LocalizationProvider'
import AdminProvider, { VIEW_AS_USER } from './src/components/AdminProvider'

if (process.env.NODE_ENV === 'development') {
  Logger.LOG_LEVEL = 'DEBUG'
}

const locale =
  COOKIE_LOCALE_TO_SCHEMA_LOCALE[getCookie(document.cookie, 'locale')] ||
  'en_US'
window.locale = locale
const clientMetadata = { locale }

if (window.location.pathname === '/signup' && window.location.search) {
  const queryParams = getParams(window.location.search)
  if (queryParams.userType) {
    clientMetadata.userType = queryParams.userType
  }
  if (queryParams.locale) {
    clientMetadata.locale = queryParams.locale
  }
  if (queryParams.group) {
    clientMetadata.group = queryParams.group
  }
  if (queryParams.cardiologist === 'true') {
    clientMetadata.cardiologist = 'true'
  }
  if (Object.keys(queryParams).includes('demo')) {
    clientMetadata.isDemo = true
  }
}

window.AUTH_CONFIG = Auth.configure({
  region: process.env.Region,
  userPoolId: process.env.CognitoUserPoolUsers,
  userPoolWebClientId: process.env.CognitoUserPoolClientWeb,
  identityPoolId: process.env.CognitoIdentityPoolUsers,
  authenticationFlowType: 'USER_PASSWORD_AUTH', // must in order to enable user migration flow
  mandatorySignIn: false,
  clientMetadata
})

let systemClockOffset = undefined

const link = ApolloLink.from([
  new ApolloLink((operation, forward) => {
    return forward(operation).map((result) => {
      const log = operation.getContext().response.headers.get('x-log-id')
      if (log) {
        Sentry.withScope((scope) => {
          scope.setContext('log', {
            graphQLLogId: log
          })
          scope.setLevel('info')
        })
      }
      return result
    })
  }),
  new onError(({ operation, graphQLErrors, networkError, forward }) => {
    Sentry.withScope((scope) => {
      const user = Sentry.getIsolationScope().getUser()
      scope.setTransactionName(operation.operationName)
      scope.setContext('apolloGraphQLOperation', {
        operationName: operation.operationName,
        variables: JSON.stringify(operation.variables)
      })
      scope.setLevel('error')

      if (graphQLErrors && graphQLErrors.length) {
        graphQLErrors.forEach(({ errorType, message, locations, path }) => {
          if (
            (!user || user.id === ANONYMOUS_USER_ID) &&
            message === 'AccessDenied'
          ) {
            return
          }
          Sentry.captureMessage(message, {
            fingerprint: ['{{ default }}', '{{ transaction }}'],
            contexts: {
              apolloGraphQLError: {
                errorType,
                message,
                locations: locations ? JSON.stringify(locations) : undefined,
                path
              },
              apolloNetworkError: {
                error: networkError
                  ? JSON.parse(makeErrorJSONFriendly(networkError))
                  : networkError
              }
            }
          })
        })
      } else if (
        networkError &&
        !(
          (!user || user.id === ANONYMOUS_USER_ID) &&
          networkError.message === 'AccessDenied'
        )
      ) {
        Sentry.captureMessage(networkError.message, {
          fingerprint: ['{{ default }}', '{{ transaction }}'],
          contexts: {
            apolloNetworkError: {
              error: JSON.parse(makeErrorJSONFriendly(networkError))
            }
          }
        })
      }
    })

    // when login with wrong date, cognito user pool client stores clockDrift with the diff
    // after the date is changed, the clockDrift isn't updated and may cause issue when tokens (id,, access, etc.) has to be refreshed but the client not aware about it
    // in that case, an error returns and the auth logic generates credentials for unauthorized access while logged in user and sessions still active
    // this is causing normal calls to receive AccessDenied errors
    // in this case, we need to update the clockDrift and retry the operation
    // this issue is caused because its internal process inside Auth logic and we can't catch the expired token error
    if (
      graphQLErrors &&
      graphQLErrors.length &&
      graphQLErrors.find(({ message }) => message === 'AccessDenied')
    ) {
      return fromPromise(
        Promise.all([Auth.currentSession(), Auth.currentUserPoolUser()]).catch(
          () => [null, null]
        )
      ).flatMap(([userSession, user]) => {
        if (!userSession || !user) {
          return new Observable((observer) =>
            observer.error(
              new ApolloError({
                graphQLErrors
              })
            )
          )
        }
        const serverDate = operation.getContext().response.headers.get('date')
        const clockDrift = Math.floor(
          (new Date() - new Date(serverDate).getTime()) / 1000
        )
        userSession.clockDrift = clockDrift
        user.setSignInUserSession(userSession) // fix clock drift in cognito user pool session and force session refresh
        Auth.Credentials._nextCredentialsRefresh = Date.now() - 1 // force cognito identity credentials refresh
        return forward(operation)
      })
    }
  }),
  new RetryLink({
    attempts: (count, operation, error) => {
      if (process.env.NODE_ENV === 'development' || count >= 5) {
        return false
      }
      if (
        error.response &&
        error.response.headers.get('x-amzn-errortype') ===
          'InvalidSignatureException'
      ) {
        const serverTime = error.response.headers.get('date')
        const offset = new Date(serverTime).getTime() - Date.now()
        // 15 minutes
        if (Math.abs(offset) < 1000 * 60 * 15) {
          systemClockOffset = offset
          return true
        }
        return false
      }
      return (
        !error ||
        (error.name === 'TypeError' &&
          [
            'Failed to fetch',
            'Load failed',
            'NetworkError when attempting to fetch resource.'
          ].includes(error.message)) ||
        (error.name === 'ServerError' && error.statusCode === 500) ||
        (error.name === 'Error' && error.message === 'Timeout exceeded') ||
        error.name === 'AbortError' ||
        (error &&
          error.result &&
          error.result.errors &&
          error.result.errors.length > 0 &&
          [
            'ExpiredTokenException',
            'UnrecognizedClientException',
            'INVALID_SIGNATURE'
          ].includes(error.result.errors[0].errorType))
      )
    },
    delay: (count) => {
      return 500 * 2 ** count
    }
  }),
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...(VIEW_AS_USER.id ? { 'X-View-As-User-Id': VIEW_AS_USER.id } : {})
      }
    }))
    return forward(operation)
  }),
  new SentryLink({
    setTransaction: false,
    uri: `${process.env.ServiceEndpoint}/graphql`,
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeError: true
    }
  }),
  new ApolloLinkTimeout(10000), // https://github.com/apollographql/apollo-feature-requests/issues/429
  setContext(async ({ operationName, variables, query }, { headers }) => {
    const uri = `${
      process.env.ServiceEndpoint
    }/graphql?cache_buster=${new Date().getTime()}`
    const credentials = await currentCredentials()
    const httpRequest = await signGraphQLRequest(
      uri,
      headers,
      {
        operationName: operationName,
        variables: variables,
        query: print(query)
      },
      credentials,
      process.env.Region,
      systemClockOffset ? new Date(Date.now() + systemClockOffset) : undefined
    )

    return {
      uri,
      headers: {
        ...httpRequest.headers
      }
    }
  }),
  new HttpLink()
])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    possibleTypes: JSON.parse(process.env.GraphqlSchemaPossibleTypes),
    typePolicies: {
      User: {
        fields: {
          address: {
            merge: true
          },
          settings: {
            merge: true
          },
          activityStream: relayStylePagination()
        }
      },
      Clinic: {
        fields: {
          address: {
            merge: true
          },
          logo: {
            merge: true
          }
        }
      },
      LogData: {
        keyFields: false
      },
      StudyData: {
        fields: {
          AnonymousPatientData: {
            merge: true
          },
          patient: {
            merge: true
          }
        },
        keyFields: false
      },
      Study: {
        fields: {
          AnonymousPatientData: {
            merge: true
          },
          patient: {
            merge: true
          }
        }
      },
      ReportData: {
        keyFields: false
      },
      AnonymousPatientData: {
        fields: {
          animal: {
            merge: true
          }
        }
      },
      PatientData: {
        fields: {
          animal: {
            merge: true
          }
        },
        keyFields: false
      },
      Patient: {
        fields: {
          animal: {
            merge: true
          }
        },
        keyFields: false
      },
      CaregiverData: {
        keyFields: false
      },
      GroupData: {
        keyFields: false
      },
      AuditData: {
        keyFields: false
      },
      ActivityData: {
        keyFields: false
      },
      Address: {
        keyFields: false
      },
      File: {
        keyFields: false
      },
      CommentData: {
        keyFields: false
      },
      DiagnosisData: {
        keyFields: false
      },
      AnalysisData: {
        keyFields: false
      },
      MeasurementsData: {
        keyFields: false
      },
      UserMarksData: {
        keyFields: false
      },
      RecordEcgRestData: {
        keyFields: false
      },
      RecordEcgHolterData: {
        keyFields: false
      },
      RecordStethoscopeData: {
        keyFields: false
      },
      RecordSpirometerData: {
        keyFields: false
      },
      RecordQuestionnaireData: {
        keyFields: false
      },
      RecordAttachmentData: {
        keyFields: false
      },
      RecordAttachmentGroupData: {
        keyFields: false
      },
      ReferralsConfigItem: {
        keyFields: false
      },
      ReferralsQueueItem: {
        keyFields: false
      },
      ReferralData: {
        keyFields: false
      },
      Referral: {
        keyFields: false
      },
      CreditCard: {
        keyFields: false
      },
      PaymentToken: {
        keyFields: false
      },
      StudyShareData: {
        keyFields: false
      },
      StudyShareDataInMyStudies: {
        keyFields: ['studyId']
      },
      ShareData: {
        keyFields: false
      },
      PatientConnectionLink: {
        keyFields: false
      },
      CaregiverConnectionLink: {
        keyFields: false
      },
      AIResultConnectionLink: {
        keyFields: false
      },
      ClinicConnectionLink: {
        keyFields: false
      },
      GroupConnectionLink: {
        keyFields: false
      },
      QueueConnectionLink: {
        keyFields: false
      },
      ReturnValue: {
        keyFields: false
      },
      DeviceData: {
        keyFields: ['id', 'clinic', ['id']]
      }
    }
  })
})

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <LocalizationProvider>
        <AdminProvider
          viewAsUserId={window.CURRENT_USER_ID_FROM_GENERATE_PDF_REPORT}
        >
          {element}
        </AdminProvider>
      </LocalizationProvider>
    </ApolloProvider>
  )
}

export const wrapPageElement = ({ element }) => {
  return <Sentry.ErrorBoundary>{element}</Sentry.ErrorBoundary>
}

export const shouldUpdateScroll = () => {
  return true // needed in order to prevent scroll to top when clicking on context viewer
}
